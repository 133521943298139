import { TX_HASH_PER_ACCOUNT_MAP } from "@global/consts";
import { Web3 } from "../../../global/utils";

export const mintHandler = async (
  mintCount: number,
  onError: (error: string) => void
): Promise<string> => {
  try {
    const { provider, account, contracts: { minting: mintContract } } = Web3;

    const feeData = await provider.getFeeData();
    const signer = await provider.getSigner(account);
    const mintWithSigner = await mintContract.connect(signer);

    const txGasLimit = await mintWithSigner.estimateGas.mint();

    const tx = await mintWithSigner.mint({
      gasLimit: txGasLimit.mul(2),
      maxFeePerGas: feeData.maxFeePerGas,
      maxPriorityFeePerGas: feeData.maxPriorityFeePerGas,
    });

    const currentAccountPerTxMap = localStorage.getItem(TX_HASH_PER_ACCOUNT_MAP);
    const newEntry = { [account.toLowerCase()]: tx.hash };
    const map = currentAccountPerTxMap ? { ...JSON.parse(currentAccountPerTxMap), ...newEntry } : newEntry
    localStorage.setItem(TX_HASH_PER_ACCOUNT_MAP, JSON.stringify(map))

    return tx.hash;
  } catch (error: unknown) {
    console.error('-- mintHandler error --');
    console.error(error)
    console.error('-- mintHandler error --');

    return '';
  }
}