import { Fade } from "@mui/material";
import { MetamaskButton } from "@components"
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { ValentineGameText, ContentWrap, StyledHeader } from './styles';

export const NotConnectedView = () => (
  <Fade in unmountOnExit timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}>
    <ContentWrap>
      <StyledHeader headerText="Ask valentine" />

      <MetamaskButton sx={{ my: 1 }} timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2} />

      <ValentineGameText sx={{ my: 4 }}>
        IT IS VALENTINE’S DAY AND VALENTINE STILL HAS NO VALENTINE. TRY YOUR CHANCE BY WRITING HER YOUR NICEST WORDS. BEWARE, VALENTINE IS VERY DESIRED, COMPETITION COULD BE THOUGH.
      </ValentineGameText>
    </ContentWrap>
  </Fade>
)