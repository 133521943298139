import { useQuery } from "@apollo/client";
import { Layout } from "@component/layout/layout";
import { Box, Fade, Typography } from "@mui/material";
import { YourRankTable } from './your-rank-table'
import { HideIn, ViewHeader } from '@global/components'
import { GameOverWrap, RankingInfo, RankingInfoHeader, RankingRow, RankNumber, Wrap } from "./styles";
import { rankingsQuery, ValDay23LeaderboardEntry } from "@gql/genesis-drop";
import { shortenAddress } from "@component/layout/header/components/wallet-status-dropdown/helpers/shorten-address";
import { useContext, useEffect, useState } from "react";
import { Colors } from "@global/colors";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { PageContext } from "@component/page-provider/context";

const letters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";

export const Rankings = () => {
  const [showGameOverText, setShowGameOverText] = useState(false);

  const { isGameOver } = useContext(PageContext);
  const { data } = useQuery<ValDay23LeaderboardEntry>(rankingsQuery, {
    nextFetchPolicy: 'network-only'
  });

  useEffect(() => {
    if (data !== null) {

      let iteration = 0;

      const h1 = document.querySelector("h1");

      if (h1 === null) {
        return;
      };

      let interval = setInterval(() => {
        h1.innerText = h1.innerText
          .split("")
          .map((letter, index) => {
            if (index < iteration) {

              return h1.parentElement!.dataset!.value![index];
            }

            return letters[Math.floor(Math.random() * 26)]
          })
          .join("");

        if (iteration >= h1.parentElement!.dataset!.value!.length) {
          clearInterval(interval);
          setShowGameOverText(true);
        }

        if (iteration % 2 < 1) {
          h1.style.color = Colors.SassyPink;
          h1.style.fontWeight = 'bold';
        } else {
          h1.style.color = Colors.White;
          h1.style.fontWeight = 'regular';
        }

        iteration += 1 / 3;
      }, 100);
    }
  }, [data, isGameOver]);

  return (
    <Layout dimmed>
      <Box sx={{ display: 'flex', height: '60vh', flexDirection: 'column', alignItems: 'center', justifyContent: 'flex-start' }}>
        {
          isGameOver &&
          <GameOverWrap>
            <ViewHeader
              data-value="Game Over"
              headerText="AVGM ROVE"
            />
            <Fade in={showGameOverText} timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 10}>
              <Typography>
                Valentine is no longer accepting letters.
              </Typography>
            </Fade>
            <br />
            <Fade in={showGameOverText} timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 30}>
              <Typography>
                She is thrilled to have received so many valentines and is still in the process of picking her favorites.
                <br />
                Consequently, the current state of the leaderboard below <b><u>may change!</u></b>
                <br />
                <br />
              </Typography>
            </Fade>
            <Fade in={showGameOverText} timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 40}>
              <Typography>
                <b>Thank you again for participating!</b>
                <br />
                Expect rewards to arrive in your wallets soon and keep in touch for exciting new announcements in the coming weeks.
              </Typography>
            </Fade>
          </GameOverWrap>
        }
        <Wrap>
          <YourRankTable />

          {data?.valDay23Leaderboard &&
            <Box style={{ width: '100%', marginTop: '24px' }}>
              <RankingRow>
                <RankingInfoHeader>Rank</RankingInfoHeader>
                <RankingInfoHeader>Wallet</RankingInfoHeader>
                <RankingInfoHeader>Top Score</RankingInfoHeader>
              </RankingRow>
              <Box sx={{ height: '50vh' }}>
                {data.valDay23Leaderboard.map((info, index, arr) => (
                  <RankingRow sx={{ mb: 2, pb: index === arr.length - 1 ? 4 : 0 }} key={info.address}>
                    <RankingInfo>
                      <RankNumber>
                        #{index + 1}
                      </RankNumber>
                    </RankingInfo>
                    <HideIn view="down.sm">
                      <RankingInfo>
                        {info.address}
                      </RankingInfo>
                    </HideIn>

                    <HideIn view="up.md">
                      <RankingInfo>
                        {shortenAddress(info.address)}
                      </RankingInfo>
                    </HideIn>
                    <RankingInfo>{info.highScore}</RankingInfo>
                  </RankingRow>
                ))}
              </Box>
            </Box>
          }
        </Wrap>
      </Box>
    </Layout >
  );
}