import { Box, styled } from "@mui/material";
import { ValentineBackgroundImage } from '@global/assets';
import { transientProps } from "@global/utils";


export const PageWrapper = styled(Box, transientProps('dimmed'))<{ dimmed?: boolean }>(({ theme, dimmed }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  overflow: 'auto',
  backgroundImage: `url(${ValentineBackgroundImage})`,
  ...(dimmed ? {
    background: 'linear-gradient(rgba(0, 0, 0, 0.80), rgba(0, 0, 0, 0.80)) ,url(/static/media/valentine-background.e2bb5a6759e76a9911d2.png) center/cover',
  } : {}),
  backgroundSize: 'cover',
  backgroundPositionY: '25%',
  minHeight: '100vh',
  maxHeight: '100vh',
  [theme.breakpoints.down('sm')]: {
    justifyContent: 'flex-start',
  }
}));

export const Main = styled('main')(({ theme }) => ({
  maxWidth: '100vw',
  minHeight: '80vh',
  maxHeight: '100vh',
  flexWrap: 'wrap',
  position: 'relative',
  padding: theme.spacing(4, 3, 0),
  display: 'flex',
  justifyContent: 'center',
}));