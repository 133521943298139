import { useContext, useEffect, useState } from "react";
import { shortenAddress } from "@component/layout/header/components/wallet-status-dropdown/helpers/shorten-address";
import { Web3Context } from "@component/web3-provider/context";
import {
  BoldText, RankingTable, StatsText, StatsWrap, StatsOuterWrap,
  WalletInfoWrap, LoggedInWrap, SemiBoldText, StyledMetamaskButton
} from "./styles";
import { userStatsQuery, ValDay23UserStatsEntry } from "@gql/genesis-drop";
import { Colors } from "@global/colors";
import { useQuery } from "@apollo/client";

export const YourRankTable = () => {
  const { account, disconnectWallet } = useContext(Web3Context);

  const [userStats, setUserStats] = useState<{ score: any, rank: any }>({ score: '-', rank: '-' });

  const { data } = useQuery<ValDay23UserStatsEntry>(userStatsQuery, {
    variables: {
      options: {
        address: account
      }
    }
  });

  useEffect(() => {
    if (!!data) {
      const stats = data.valDay23UserStats;
      setUserStats({
        score: stats.highScore,
        rank: stats.rank,
      });
    }
  }, [data]);

  return (
    <RankingTable>
      <WalletInfoWrap>
        {typeof account === 'string' && account.length > 0 ?
          <LoggedInWrap>
            <SemiBoldText sx={{ mr: 2 }}>
              {shortenAddress(account)}
            </SemiBoldText>
            <BoldText
              sx={{ color: Colors.SassyPink, ':hover': { cursor: 'pointer' } }}
              onClick={disconnectWallet}
            >
              LOG OUT
            </BoldText>
          </LoggedInWrap>
          :
          <StyledMetamaskButton />
        }
      </WalletInfoWrap>
      <StatsOuterWrap>
        <StatsWrap sx={{ mr: { xs: 0, sm: 0, md: 2, lg: 2, xl: 2 }, mb: { xs: 2, sm: 2, md: 0, lg: 0, xl: 0 } }}>
          <BoldText sx={{ mr: 2 }}>
            Your rank
          </BoldText>
          <StatsText>{account ? userStats.rank : '-'}</StatsText>
        </StatsWrap>

        <StatsWrap>
          <BoldText sx={{ mr: 2 }}>
            Your score
          </BoldText>
          <StatsText>{account ? userStats.score : '-'}</StatsText>
        </StatsWrap>
      </StatsOuterWrap>
    </RankingTable>
  );
};