import { Colors } from "@global/colors";
import { styled } from "@mui/material";
import { LoadingButton } from '@mui/lab';
import { transientProps } from '@global/utils';

export const StyledButton = styled(LoadingButton, transientProps('boldText', 'secondary'))<{ secondary?: boolean }>(({ theme, size, secondary }) => ({
  borderRadius: '12px',
  fontWeight: 'bold',
  color: secondary ? Colors.White : Colors.Black,
  background: secondary ? 'unset' : Colors.SassyPink,
  border: secondary ? 0 : `2px solid ${Colors.Black}`,
  transition: 'background 1s ease-in-out',
  boxShadow: '4px 4px 4px rgba(0, 0, 0, 0.5)',
  padding: theme.spacing(2, 4),

  '&:disabled': {
    opacity: '0.5 !important',
    cursor: 'not-allowed  !important',
    pointerEvents: 'all !important',
  },

  '&:hover': {
    background: secondary ? 'unset' : Colors.SassyPink,
  },

  '.MuiTypography-root': {
    fontSize: theme.typography.pxToRem(size === 'large' ? 20 : 16),
    // fontWeight: boldText ? theme.typography.fontWeightBold : theme.typography.fontWeightRegular,
    fontWeight: theme.typography.fontWeightSemiBold,
  },

  [theme.breakpoints.down('md')]: {
    height: size === 'large' ? '50px' : '40px',

    '.MuiTypography-root': {
      fontSize: theme.typography.pxToRem(size === 'large' ? 16 : 12)
    }
  }
}));
