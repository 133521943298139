import { Typography } from "@mui/material";
import { Notification } from "@components";
import { ErrorProps } from "./types";

export const Error: React.FC<ErrorProps> = ({ onClose, error }: ErrorProps) =>
  <Notification
    error
    onClose={onClose}
  >
    <Typography>
      {error}
    </Typography>
  </Notification>

