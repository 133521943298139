import { ethers } from "ethers";
import { Errors } from "@global/consts/errors";
import { Web3ErrorType } from "@global/types";
import { OnErrorCallback, Web3ErrorData } from "./types";

export const handleWeb3Error = (
  error: Web3ErrorType,
  onError?: OnErrorCallback,
  data?: Web3ErrorData
) => {
  const errorCodesToShowUser = [ethers.errors.INSUFFICIENT_FUNDS, Errors.codes.AlreadyProcessing];

  if (errorCodesToShowUser.includes(Number(error.code))) {
    let normalizedError;

    switch (error.code) {
      case ethers.errors.INSUFFICIENT_FUNDS: {
        normalizedError = Errors.messsages.InsufficientFunds(data?.insufficientFunds);
        break;
      }
      case Errors.codes.AlreadyProcessing:
        normalizedError = Errors.messsages.AlreadyProcessing(data?.rejectedRequestType);
        break;
    };

    onError?.(normalizedError as string);
  }
}