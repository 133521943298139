
import { Box, IconButton, styled, Typography } from "@mui/material";
import { flicker, grow, invalidate } from "@global/animations";
import { transientProps } from "@global/utils";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { GradientText } from "@global/components";
import { Colors, pinkToBlue } from "@global/colors";

export const CarouselWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center'
});

export const CarouselImagesWrap = styled('div')(({ theme }) => ({
  width: '400px',
  minHeight: '350px',
  display: 'flex',
  overflow: 'hidden',
  alignItems: 'center',
  marginLeft: theme.spacing(4),
  marginRight: theme.spacing(4),
}));

export const ValentineWrap = styled(Box)({
  width: '50%',
  position: 'relative',
  display: 'inline-flex',
  flexdirection: 'column',
  justifyContent: 'center',
  alignItems: 'space-around',
  flexWrap: 'wrap'
});

export const RevealedImg = styled('img', transientProps('isSelected', 'onlyOne'))<{ isSelected: boolean, onlyOne?: boolean }>(({ theme, isSelected, onlyOne }) => ({
  width: onlyOne ? '70%' : '100%',
  position: 'relative',
  animation: `
  ${isSelected ?
      `${grow} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 1.25}ms cubic-bezier(0.39, 0.58, 0.57, 1) both` :
      `${invalidate} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}ms cubic-bezier(0.39, 0.58, 0.57, 1) both`
    }`,
  transform: `scale(${isSelected ? 1.5 : 1}) transformX(-600px)`,
  zIndex: isSelected ? 1000000 : 100000,
  borderRadius: '24px',
  filter: `drop-shadow(0px 4px 15px rgba(255, 255, 255, ${isSelected ? '0.25' : '0.1'}))`
}));

export const StyledText = styled(GradientText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(24),
  bottom: '-65%',
  left: '50%',
  transform: 'translateX(-50%)',
  whiteSpace: 'nowrap',
  position: 'absolute',
}))

export const StyledButton = styled(IconButton)(({ theme }) => ({
  '&:active': {
    transform: 'scale(0.975)',
  },

  [theme.breakpoints.down('sm')]: {
    position: 'absolute',
  }
}))

export const ArrowWrapper = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: '48px',
  height: '48px',
  background: 'linear-gradient(92.11deg, rgba(255, 145, 250, 0.2) 1.79%, rgba(196, 223, 254, 0.2) 60.99%)',
  borderRadius: '100px',

  svg: {
    position: 'absolute',
    fill: Colors.White,
    transform: 'translate(-50%, -50%)',
    top: '50%',
    left: '50%',
    width: '65% !important',
    height: '65% !important',
  },

  [theme.breakpoints.down('md')]: {
    width: '32px',
    height: '32px',
  }
}))

export const ArrowWrapperInner = styled(Box)({
  width: 'inherit',
  height: 'inherit',
  borderRadius: 'inherit',
  padding: '2px',
  background: pinkToBlue(1, 'right'),
  WebkitMask: `
    linear-gradient(#fff 0 0) content-box,
    linear-gradient(#fff 0 0)`,
  WebkitMaskComposite: 'xor',
  maskComposite: 'exclude',
  pointerEvents: 'none',
})

export const OverlayWrap = styled(Box)(({ theme }) => ({
  position: 'absolute',
  width: '80vw',
  height: '60vh',
  marginLeft: '5vw',
  top: '20vh',
  opacity: '95%',
  background: '#874dd3',
  color: '#fff',
  padding: theme.spacing(4, 8, 6),
  zIndex: theme.zIndex.modal + 100000,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  borderRadius: '12px',

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(24),
    padding: theme.spacing(2),
  }
}));

export const StyledHeaderWrap = styled('div')({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  textAlign: 'center'
})

export const StyledHeader = styled(Typography)(({ theme }) => ({
  background: 'linear-gradient(268.48deg, #FFFFFF 1.31%, #FFFFFF 1.32%, rgba(255, 255, 255, 0.79) 98.52%, rgba(255, 255, 255, 0.59) 98.52%)',
  'WebkitBackgroundClip': 'text',
  'WebkitTextFillColor': 'transparent',
  'backgroundClip': 'text',
  'textFillColor': 'transparent',
  'textShadow': '0px 0px 40px rgba(255, 145, 250, 0.6)',
  animation: `${flicker} 1.825s linear both`,
  animationDelay: `${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}ms`,

  padding: theme.spacing(2, 4),
  fontSize: theme.typography.pxToRem(48),
  fontWeight: theme.typography.fontWeightExtraBold,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(24),
    paddingLeft: theme.spacing(5),
    paddingRight: theme.spacing(5)
  }
}));

export const StyledSubHeader = styled(Typography)(({ theme }) => ({
  textTransform: 'capitalize',
  fontSize: theme.typography.pxToRem(20),
  fontWeight: theme.typography.fontWeightRegular,
  textShadow: '0px 0px 40px rgba(255, 145, 250, 0.6)',

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(12),
  }
}));

export const StyledCloseCTA = styled(IconButton)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  position: 'absolute',
  top: theme.spacing(2),
  right: theme.spacing(4),
  zIndex: theme.zIndex.modal,

  'p': {
    color: Colors.White
  },

  svg: {
    fill: 'rgba(255, 255, 255, 0.54)',
    transform: 'scale(0.8)',
    marginRight: theme.spacing(0.5)
  },

  [theme.breakpoints.down('sm')]: {
    right: theme.spacing(2),
  }
}));