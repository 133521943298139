import { useState, useEffect } from "react";
import { Box, Fade, Typography } from "@mui/material";
import { ViewHeader } from "@global/components";
import { ContentWrapCenter, StyledViewHeader } from "./styles";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { ValentineLetter } from "./valentine-letter";
import { PlayAgainBtn } from "./play-again-btn";
import { TIME_UNTIL_NEW_GAME_LS_KEY, USER_STATS_LS_KEY } from "./consts";
import { Maybe } from "@global/types";
import { valDay23EntrySubmitMutationData } from "@gql/genesis-drop";
import { Colors } from "@global/colors";

export const ValentineGameView = () => {
  const [revealAt, setRevealAt] = useState<Maybe<number>>(null);
  const [userStats, setUserStats] = useState<Maybe<valDay23EntrySubmitMutationData['valDay23EntrySubmit']>>(null);

  const handlePlayAgainClick = () => {
    setUserStats(null);
    localStorage.removeItem(TIME_UNTIL_NEW_GAME_LS_KEY);
    localStorage.removeItem(USER_STATS_LS_KEY);
  }

  useEffect(() => {
    const localStorageRevealAt = localStorage.getItem(TIME_UNTIL_NEW_GAME_LS_KEY);

    if (localStorageRevealAt !== null) {
      setRevealAt(Number(localStorageRevealAt));
    }

    const localStorageUserStats = localStorage.getItem(USER_STATS_LS_KEY);
    if (localStorageUserStats !== null) {
      const userStats = JSON.parse(localStorageUserStats);
      setUserStats(userStats);
    }
  }, [userStats?.score]);

  if (userStats !== null) {
    return (
      <ContentWrapCenter>
        <StyledViewHeader
          headerText="Your Final Score"
          subHeaderText={userStats.score}
        />
        <Box sx={{ display: 'flex' }}>
          <ViewHeader
            headerText="TOP WORDS"
            sx={{ mr: 3, h1: { fontSize: '10px' } }}
            subHeaderProps={{ sx: { padding: 2, h2: { fontSize: '14px' } } }}
            subHeaderText={(userStats.correctWords as unknown as number) > 0 ? userStats.correctWords : "-"}
          />
          <ViewHeader
            headerText="BONUS"
            sx={{ mr: 3, h1: { fontSize: '10px' } }}
            subHeaderProps={{ sx: { padding: 2, h2: { fontSize: '14px' } } }}
            subHeaderText={`x${userStats.coreMultiplier}`}
          />
          <ViewHeader
            headerText="TRY N°"
            sx={{ h1: { fontSize: '10px' } }}
            subHeaderProps={{ sx: { padding: 2, h2: { fontSize: '14px' } } }}
            subHeaderText={userStats.numTries}
          />
        </Box>

        <ViewHeader
          headerText="YOUR RANKING FOR THIS SCORE"
          sx={{ h1: { fontSize: '16px' } }}
          subHeaderProps={{ sx: { h2: { fontSize: '24px' } } }}
          subHeaderText={userStats.rank}
        />

        <Typography sx={{ textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)', fontWeight: 800, fontSize: '13px', color: Colors.White, mt: 4, mb: 2 }}>
          YOU DID BETTER THAN {userStats.percentile}% OF OTHER PLAYERS.
        </Typography>

        <PlayAgainBtn
          revealAt={revealAt}
          onClick={handlePlayAgainClick}
        />
      </ContentWrapCenter>
    )
  };

  return (
    <Fade in unmountOnExit timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}>
      <ContentWrapCenter>
        <ValentineLetter onUpdate={setUserStats} />
      </ContentWrapCenter>
    </Fade>
  )
}