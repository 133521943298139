import { Colors, fadedWhite03, fadedWhite08 } from '@global/colors';
import { styled, Button as MuiButton, Box, Theme, Typography } from '@mui/material';
import DownArrowIcon from '@mui/icons-material/KeyboardArrowDownOutlined';
import { Button, GradientText } from '@global/components';
import { EXPAND_TOGGLE_ANIMATION_TIMEOUT_MILLISECONDS } from './consts';
import { FADE_TRANSITION_TIMEOUT_MILLISECONDS } from '@global/components/hover-overlay/consts';
import { transientProps } from '@global/utils';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';

const commonProps = (theme: Theme, hovered: boolean) => ({
  border: `1px solid ${fadedWhite03}`,
  borderColor: hovered ? fadedWhite08 : fadedWhite03,
  transition: `border-color ${FADE_TRANSITION_TIMEOUT_MILLISECONDS}ms ease-in`,
  zIndex: theme.zIndex.appBar,
})

export const BaseRectButton = styled(MuiButton)(({ theme }) => ({
  borderRadius: 0,
  height: '44px',

  padding: theme.spacing(1.5, 2),

  '.MuiTypography-root': {
    fontSize: theme.typography.pxToRem(14)
  },

  [theme.breakpoints.down('md')]: {
    '.MuiTypography-root': {
      fontSize: theme.typography.pxToRem(12)
    }
  },
}));

export const StyledDropdownBtn = styled(BaseRectButton, transientProps('hovered'))<{ hovered: boolean }>(({ theme, hovered }) => ({
  textTransform: 'none',
  ...commonProps(theme, hovered),
}));

export const StyledDropdownContainer = styled(Box, transientProps('hovered'))<{ hovered: boolean }>(({ theme, hovered }) => ({
  position: 'absolute',
  top: 'calc(100% - 1px)',
  textAlign: 'left',
  color: Colors.White,
  width: '100%',
  padding: theme.spacing(1, 2.5, 2, 2.5),
  fontWeight: theme.typography.fontWeightRegular,
  background: Colors.CoolDarkBlue,
  ...commonProps(theme, hovered)
}));

export const ShortenedAddressText = styled(Typography)<{ component?: string }>(({ theme }) => ({
  fontWeight: theme.typography.fontWeightBold,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(14)
  }
}));

export const StyledDownArrowIcon = styled(DownArrowIcon, transientProps('reverse'))<{ reverse: boolean }>(({ reverse }) => ({
  color: fadedWhite03,
  transition: `transform ${EXPAND_TOGGLE_ANIMATION_TIMEOUT_MILLISECONDS}ms cubic-bezier(0.55, 0.06, 0.68, 0.19)`,
  transform: reverse ? 'rotate(180deg)' : 'rotate(0)'
}));


export const StyledConnectedText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightLight,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(12)
  }
}))

export const StyledGradientText = styled(GradientText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium
}))

export const ChangeWalletButton = styled(Button)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: theme.typography.fontWeightMedium
  }
}))

export const StyledMainWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  zIndex: theme.zIndex.appBar
}))

export const DisconnectButton = styled(BaseRectButton)(({ theme }) => ({
  marginTop: theme.spacing(1.5),
  color: Colors.Black,
  background: `linear-gradient(90.71deg, ${Colors.SassyPink} -14.5%, ${Colors.SkyBlue} 104.68%)`,

  '.MuiTypography-root': {
    fontWeight: theme.typography.fontWeightMedium,
    transform: 'scale(1)',
    transition: `transform ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms cubic-bezier(0.22, 0.61, 0.36, 1)`,
  },

  '&:hover': {
    '.MuiTypography-root': {
      transform: 'scale(0.975)',
      fontWeight: theme.typography.fontWeightBold,
    }
  },
}));
