import { Colors } from "@global/colors"
import { Button } from "@global/components";
import { Box, Select, styled, Typography } from "@mui/material"

export const ContainerBox = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
  background: '#141517',
  opacity: 0.9,
  borderRadius: '12px',
  boxShadow: '0 0 4px 4px rgba(255, 145, 250, 0.2)',

  [theme.breakpoints.down('sm')]: {
    marginTop: theme.spacing(5),
    padding: theme.spacing(1),
    alignItems: 'flex-end'
  }
}));

export const SelectionCTA = styled(Typography)(({ theme }) => ({
  color: Colors.SassyPink,
  padding: theme.spacing(3, 0, 3, 5),
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightMedium,
  marginRight: theme.spacing(3),
  cursor: 'pointer',
  display: 'inline-block',
}))

export const SelectedNumberText = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  opacity: 0.3,
  display: 'inline-block',
  marginRight: theme.spacing(1.5),
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightMedium,
}))

export const MyValentinesText = styled(Typography)(({ theme }) => ({
  width: '100%',
  textAlign: 'left',
  color: Colors.White,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightBold,
  textShadow: '0px 0px 40px rgba(255, 145, 250, 0.6)',
}))

export const StyledRevealedText = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  fontSize: theme.typography.pxToRem(10),
  display: 'inline-block',

  'span': {
    color: Colors.SassyPink,
    fontSize: theme.typography.pxToRem(14),
  },
}));

export const StyledMobileButton = styled(Button)(({ theme }) => ({
  width: '80%',
  left: '10%',
  right: '10%',
  bottom: '0',
  position: 'fixed',
  maxWidth: 'none',
  opacity: 1,
  fontSize: theme.typography.pxToRem(16),
  fontWeight: theme.typography.fontWeightExtraBold,
  zIndex: theme.zIndex.appBar,

  '&:disabled': {
    background: 'linear-gradient(91.55deg, rgba(255, 145, 250, 0.2) 12.27%, rgba(196, 223, 254, 0.2) 97.85%), #0F0F0F',
    opacity: '1 !important',
    cursor: 'not-allowed  !important',
    pointerEvents: 'all !important',
  },
}));

export const StyledSelect = styled(Select)(({ theme }) => ({
  color: Colors.SassyPink,
  fontSize: theme.typography.pxToRem(14),

  '& svg': {
    color: Colors.SassyPink,
  },

  '> fieldset': {
    border: 'none !important;'
  }
}));

export const dropdownMenuStyles = {
  '& ul': {
    background: 'linear-gradient(90deg, #3F0F3C 0%, #3F163C 100%)',
  },

  '& li': {
    color: Colors.SassyPink,
    border: 'border: 0.5px solid rgba(255, 255, 255, 0.4);'
  },
};

export const menuItemBorder = '0.5px solid rgba(255, 255, 255, 0.4)';
