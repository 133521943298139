import React, { useEffect, useState } from 'react'
import { Button } from '@global/components'
import { PlayAgainBtnProps } from './types'

export const PlayAgainBtn: React.FC<PlayAgainBtnProps> = ({ revealAt, onClick }) => {
  const [minutesLeft, setMinutesLeft] = useState(0)
  const [secondsLeft, setSecondsLeft] = useState(0)
  const [canPlayAgain, setCanPlayAgain] = useState(false);

  const getTime = () => {
    if (revealAt === null) return;

    const timeLeft = revealAt - Date.now()

    if (timeLeft < 0) {
      setCanPlayAgain(true)
    } else {
      setMinutesLeft(Math.floor((timeLeft / 1000 / 60) % 60));
      setSecondsLeft(Math.floor((timeLeft / 1000) % 60));
    }
  }

  useEffect(() => {
    if (revealAt !== null && revealAt > 0) {
      const intervalId = setInterval(getTime, 1000);

      return () => clearInterval(intervalId);
    }
  }, [revealAt]);

  const getTimeString = () => `${String(minutesLeft).padStart(2, '0')} : ${String(secondsLeft).padStart(2, '0')}`

  return (
    <Button
      boldText
      size="large"
      sx={{ width: '250px', mb: 4 }}
      onClick={onClick}
      disabled={!canPlayAgain}
    >
      Play Again{!canPlayAgain ? ` in ${getTimeString()}` : ''}
    </Button>
  )
}
