import { ethers } from "ethers";
import { BLOCK_TO_START_FROM } from "../consts";
import { CountOccurenceMap } from "./types";

export const getWalletTokenIDs = async (walletAddress: string, tokenContract: ethers.Contract): Promise<number[]> => {
  const transferToEventsQuery = await tokenContract.filters.Transfer(null, walletAddress)
  const transferFromEventsQuery = await tokenContract.filters.Transfer(walletAddress, null)
  const transferToEvents = await tokenContract.queryFilter(transferToEventsQuery, BLOCK_TO_START_FROM)
  const transferFromEvents = await tokenContract.queryFilter(transferFromEventsQuery, BLOCK_TO_START_FROM)

  const countOccurence = (acc: CountOccurenceMap, tx: ethers.Event) => {
    const tokenId = tx.args?.tokenId.toNumber();

    return ({
      ...acc, [tokenId]: (acc[tokenId] ?? 0) + 1
    });
  };

  const transferToTokenOccurenceMap = transferToEvents.reduce(countOccurence, {}) as CountOccurenceMap;
  const transferFromTokenOccurenceMap = transferFromEvents.reduce(countOccurence, {}) as CountOccurenceMap;

  return Object.entries(transferToTokenOccurenceMap).reduce((acc: number[], [tokenId, tokenTransferToOccurence]: [string, number]) =>
    tokenTransferToOccurence > (transferFromTokenOccurenceMap[tokenId] ?? 0) ? [...acc, Number(tokenId)] : acc,
    []
  );
}
