import { ethers } from "ethers";

export type Maybe<T> = T | null;

export type Web3ErrorType = { code: number | string, message: string };

export type NewContract = (provider: ethers.Signer | ethers.providers.Provider | undefined) => ethers.Contract

export enum NotificationType {
  MAILING_LIST_ISSUE = 'MAILING_LIST_ISSUE',
  GENERIC = 'GENERIC'
}

interface EnvironmentConfig {
  REACT_APP_GQL_SERVER_URI: string
  ETHERSCAN_HOST: string,
  OPENSEA_HOST: string
}

export interface NftMetadata {
  id: number,
  imageSrc?: string,
  traits?: [{ type: string, value: string }]
}

declare global {
  interface Window {
    env: EnvironmentConfig;
  }
}

export type StakedInfo = {
  staked: number[],
  revealAt: number
}
