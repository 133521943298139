import {
  OpenSeaIcon, TwitterIcon,
  DiscordIcon, TikTokIcon,
  WebsiteIcon, MediumIcon, InstagramIcon
} from '@global/assets'
import { SocialId } from './types';

export const socials = [
  {
    id: SocialId.Twitter,
    alt: 'Twitter',
    icon: TwitterIcon,
    url: 'https://twitter.com/HomaGames'
  },
  {
    id: SocialId.Discord,
    alt: 'Discord',
    icon: DiscordIcon,
    url: 'https://discord.gg/homagang'
  },
  {
    id: SocialId.OpenSea,
    alt: 'Open Sea',
    icon: OpenSeaIcon,
    url: 'https://opensea.io/collection/homa-gang-valentine'
  },
  {
    id: SocialId.Website,
    alt: 'Homa Games website',
    icon: WebsiteIcon,
    url: 'https://www.homagames.com'
  },
  {
    id: SocialId.Instagram,
    alt: 'Instagram',
    icon: InstagramIcon,
    url: 'https://www.instagram.com/homagames'
  },
  {
    id: SocialId.TikTok,
    alt: 'Tik Tok',
    icon: TikTokIcon,
    url: 'https://www.tiktok.com/@homagames'
  },
  {
    id: SocialId.Medium,
    alt: 'Medium',
    icon: MediumIcon,
    url: 'https://medium.com/@homagames'
  },
];