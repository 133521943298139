import { Maybe, NftMetadata } from "@global/types";
import { chunks, Web3 } from "@global/utils";
import { CHUNKED_REQUESTS_COUNT } from "../consts";
import { RevealedTokenIDs } from "../types";
import { getNFTsMetadata } from "./get-nfts-metadata";

export const getWalletNFTs = async (
  walletTokenIDs: number[],
  revealedTokenIDs: RevealedTokenIDs,
  updateHandler: React.Dispatch<React.SetStateAction<Maybe<NftMetadata[]>>>,
  updateTotal: any
) => {
  const walletAddress = Web3.account;
  const mintingContract = Web3.contracts.minting;

  try {
    const tokenBalanceBigInt = await mintingContract.balanceOf(walletAddress);

    if (tokenBalanceBigInt.toNumber() < 1) {
      updateHandler([]);
    }

    const revealed = walletTokenIDs.filter((x) => revealedTokenIDs[x])
    const unrevealed = walletTokenIDs.filter((x) => !revealedTokenIDs[x])
    updateTotal(walletTokenIDs.length);
    const revealedChunked = [...chunks(revealed, CHUNKED_REQUESTS_COUNT)];
    const urevealedChunked = [...chunks(unrevealed, CHUNKED_REQUESTS_COUNT)];

    let revealedNFTs: any[] = [];

    for (let i = 0; i < revealedChunked.length; i++) {
      const walletNFTs = await getNFTsMetadata(revealedChunked[i], true);
      revealedNFTs.push(walletNFTs);
    }

    updateHandler(revealedNFTs.flatMap((nfts) => nfts));

    let unrevealedNFTs: any[] = [];
    for (let i = 0; i < urevealedChunked.length; i++) {
      const walletNFTs = await getNFTsMetadata(urevealedChunked[i], false)
      unrevealedNFTs.push(walletNFTs);
    }

    updateHandler((nfts) => [...nfts!, ...unrevealedNFTs.flatMap((nfts) => nfts)]);
  } catch (error) {
    console.error('-- getWalletNFTs() error --');
    console.error(error);
    console.error('-- getWalletNFTs() error --');

    updateHandler([]);
  }
};