import { Colors, pinkToBlue } from "@global/colors";
import { styled, Box, Typography, IconButton, keyframes } from "@mui/material";
import AddIcon from '@mui/icons-material/AddCircle';
import RemoveIcon from '@mui/icons-material/RemoveCircle';
import { TextField } from "@global/components";
import { transientProps } from '@global/utils';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";

export const SelectorWrap = styled(Box)({
  display: 'flex',
  alignItems: 'center'
})

export const commonIconStyles = {
  color: Colors.SassyPink,
  width: '100%',
  height: '100%'
}

export const StyledAddIcon = styled(AddIcon)({
  ...commonIconStyles
})

export const StyledRemoveIcon = styled(RemoveIcon)({
  ...commonIconStyles
})

export const StyledTextField = styled(TextField, transientProps('disableHoverOverlay'))(({ theme }) => ({
  maxWidth: '120px',
  height: '70px',
  borderRadius: theme.spacing(2),

  '.MuiOutlinedInput-root': {
    height: '100%'
  },

  'input': {
    display: 'flex',
    alignItems: 'center',
    fontSize: "2rem",
    textAlign: 'center',
  },

  [theme.breakpoints.down('md')]: {
    maxWidth: '100px',
    height: '60px',
  }
}));

export const Divider = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
  marginBottom: theme.spacing(2),
  height: '1px',
  background: pinkToBlue(),
  transform: 'rotate(180deg)',
  width: '100%',
}));

export const TotalPriceWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
  flexWrap: 'wrap',
  width: 'inherit'
}));

export const StyledTotalLabel = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  textTransform: 'uppercase',
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(20),

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(16),
  }
}));


const lightUpOnChange = keyframes`
  0% {
    color: ${Colors.HomaGreen};
  }
  100% {
    color: ${Colors.SassyPink};
  }
`;

export const StyledTotalPrice = styled(Typography, transientProps('blink'))<{ blink?: boolean }>(({ theme, blink }) => ({
  color: Colors.SassyPink,
  fontWeight: theme.typography.fontWeightBold,
  fontSize: theme.typography.pxToRem(36),

  animation: blink ? `${lightUpOnChange} 3s cubic-bezier(0, 0, 0.2, 1)` : '',

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(24),
  }
}));

export const StyledIconButton = styled(IconButton)(({ theme }) => ({
  width: '50px',
  height: '50px',
  opacity: 1,
  transition: 'opacity 0.4s cubic-bezier(0.39, 0.58, 0.57, 1)',

  '&:disabled': {
    opacity: '0.4',
  },

  [theme.breakpoints.down('md')]: {
    width: '40px',
    height: '40px',
  }
}));

export const TransactionFeesDisclaimer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  opacity: 0.6,
  color: Colors.White,
  transition: `opacity ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms ease-in-out`,

  "svg": {
    marginRight: theme.spacing(0.5)
  },

  "svg, p": {
    fontSize: theme.typography.pxToRem(14)
  },

  '&:hover': {
    opacity: 0.8,
  }

}));

export const PriceDetailsWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-end',
  color: Colors.White,
  fontSize: theme.typography.pxToRem(14)
}));

