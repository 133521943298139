import { useContext, useEffect, useRef, useState } from "react";
import MetaMaskOnboarding from '@metamask/onboarding';
import { Web3Context } from "./context"
import { mintContract, stakingContract } from "./contracts";
import { Web3 } from "@global/utils";
import { mintHandler, getWalletNFTs, getRevealedTokenIDs, getTotalRevealed, hasValentineNFTs } from "./helpers";
import { Maybe, NftMetadata } from "@global/types";
import { MintCheck, RevealedTokenIDs, WalletStake } from "./types";
import { USER_WALLET_IS_DISCONNECTED } from "@global/consts";
import { PageContext } from "@component/page-provider/context";
import { getWalletTokenIDs } from "./helpers/get-wallet-token-ids";

export const Web3Provider = ({ children }: { children: any }) => {
  const { handleError } = useContext(PageContext)

  const [totalMinted] = useState<number>(-1);

  const [totalRevealed, setTotalRevealed] = useState<number>(0);
  const [mintPhase] = useState<number>(-1);
  const [account, setAccount] = useState<string>('');
  const [mintCheck, setMintCheck] = useState<Maybe<MintCheck>>(null);
  const [canWalletStake] = useState<boolean>(false);
  const [walletNFTs, setWalletNFTs] = useState<Maybe<NftMetadata[]>>(null);
  const [totalNFTs, setTotalNFTs] = useState<number>(0);
  const [walletStake, setWalletStake] = useState<Maybe<WalletStake>>(null);
  const [revealedTokenIDs, setRevealedTokenIDs] = useState<RevealedTokenIDs>({});
  const [doesWalletHoldNFTs, setDoesWalletHoldNFTs] = useState<Maybe<boolean>>(true);

  const onboarding = useRef<MetaMaskOnboarding>();

  const connectToWallet = async () => {
    if (MetaMaskOnboarding.isMetaMaskInstalled()) {
      await Web3.connectToWallet();
    } else {
      onboarding.current!.startOnboarding()
    }
  }

  const mint = (mintCount: number) => mintHandler(mintCount, handleError);

  const disconnectWallet = () => {
    setAccount('');
    setMintCheck(null);
    resetWalletNFTs();
    localStorage.setItem(USER_WALLET_IS_DISCONNECTED, JSON.stringify(true));
  }

  const resetWalletNFTs = () => {
    setRevealedTokenIDs([]);
    setWalletNFTs(null);
    setWalletStake(null);
    setTotalNFTs(0);
  }

  const updateWalletNFTs = async () => {
    const walletAddress = Web3.account;
    const mintingContract = Web3.contracts.minting;

    const walletTokenIDs = await getWalletTokenIDs(walletAddress, mintingContract);

    const revealedTokenIDs = await getRevealedTokenIDs(walletTokenIDs);
    setRevealedTokenIDs(revealedTokenIDs);

    getWalletNFTs(walletTokenIDs, revealedTokenIDs, setWalletNFTs, setTotalNFTs);
  }

  useEffect(() => {
    if (!onboarding.current) {
      onboarding.current = new MetaMaskOnboarding();
    }
  }, []);

  useEffect(() => {
    (async () => {
      if (typeof account === 'string') {
        const isNftHolder = await hasValentineNFTs();
        setDoesWalletHoldNFTs(isNftHolder);
      }
    })();
  }, [account]);

  useEffect(() => {
    if (!Web3.provider && MetaMaskOnboarding.isMetaMaskInstalled()) {
      (async () => {
        await Web3.init(
          {
            minting: mintContract,
            staking: stakingContract
          },
          {
            onAccountChange: setAccount,
            onReady: async () => {
              const totalRevealed = await getTotalRevealed();
              setTotalRevealed(totalRevealed)
            },
            onError: handleError,
          }
        )

        onboarding.current!.stopOnboarding();
      })()
    }
  }, [MetaMaskOnboarding.isMetaMaskInstalled()]);


  return (
    <Web3Context.Provider
      value={{
        mint,
        account,
        mintPhase,
        setAccount,
        totalMinted,
        totalRevealed,
        connectToWallet,
        disconnectWallet,
        mintCheck,
        walletNFTs,
        walletStake,
        canWalletStake,
        revealedTokenIDs,
        updateWalletNFTs,
        totalNFTs,
        doesWalletHoldNFTs,
        changeWallet: () => { Web3.changeWallet() },
      }}
    >
      {children}
    </Web3Context.Provider>
  )
};