import { pinkToBlue } from "@global/colors";
import { Checkbox, FormControlLabel, styled } from "@mui/material";
import { Colors } from "@global/colors";

export const StyledFormControlLabel = styled(FormControlLabel)(({ theme, disabled }) => ({
  color: Colors.White,
  opacity: disabled ? 0.5 : 1,

  [theme.breakpoints.down('md')]: {

    label: {
      marginRight: 0,
      marginLeft: 0,
    },

    p: {
      fontSize: theme.typography.pxToRem(14)
    }
  }
}));

export const StyledCheckbox = styled(Checkbox)(({ theme }) => ({
  borderRadius: 0,
  color: Colors.SassyPink,

  background: pinkToBlue(0.15),
  border: "1px solid",
  borderImage: pinkToBlue(),
  borderImageSlice: 1,

  width: '25px',
  height: '25px',
  padding: 0,

  marginRight: theme.spacing(2),

  '&.Mui-checked': {
    background: Colors.SassyPurple,
  },

  'img': {
    position: 'absolute',
  },

  [theme.breakpoints.down('md')]: {
    padding: theme.spacing(1.5),
    marginLeft: theme.spacing(1)
  }
}));