import { styled, Box, Typography } from "@mui/material";
import { Colors } from "@global/colors";
import { MetamaskButton } from "@components";

export const Wrap = styled(Box)(({ theme }) => ({
  flexDirection: 'column',

  [theme.breakpoints.down('sm')]: {
    width: '100%',
  }
}));

export const StyledMetamaskButton = styled(MetamaskButton)({
  transform: 'scale(0.7)',
})

export const RankingTable = styled(Box)(({ theme }) => ({
  border: `1px solid ${Colors.White}`,
  borderRadius: '12px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-start',

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    padding: theme.spacing(2),
  }
}));

export const WalletInfoWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  color: Colors.White,
  borderRight: `1px solid ${Colors.White}`,

  [theme.breakpoints.down('sm')]: {
    border: 0,
    width: '100%',
    justifyContent: 'center'
  }
}))

export const RankingRow = styled(Box)(({ theme }) => ({
  width: '100%',
  display: 'grid',
  gridTemplateColumns: '100px 1fr 100px',
  gridGap: '16px',
  alignItems: 'center',
  justifyContent: 'flex-start',
  textAlign: 'center'
}))

export const RankingInfo = styled('span')(({ theme }) => ({
  flex: '1 1 0px',
  textAlign: 'center',
  color: Colors.White,
  fontWeight: theme.typography.fontWeightSemiBold
}));

export const RankingInfoHeader = styled(Typography)(({ theme }) => ({
  flex: '1 1 0px',
  textAlign: 'center',
  color: Colors.White,
  textShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',

  fontSize: theme.typography.pxToRem(20),
  fontWeight: theme.typography.fontWeightExtraBold,
  textTransform: 'uppercase',
  marginBottom: theme.spacing(4),

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(16),
    width: 'max-content',
  }
}))

export const StatsText = styled(Typography)(({ theme }) => ({
  background: Colors.FunkyYellow,
  color: Colors.Black,
  width: '100px',
  padding: theme.spacing(1),
  borderRadius: '12px',
  fontWeight: theme.typography.fontWeightSemiBold,
  display: 'inline-block',
  textAlign: 'center'
}));

export const RankNumber = styled(StatsText)(({ theme }) => ({
  marginLeft: 'calc((100% - 100px) / 2)',
  display: 'block'
}));

export const BoldText = styled(Typography)(({ theme }) => ({
  textTransform: 'uppercase',
  color: Colors.White,
  fontWeight: theme.typography.fontWeightExtraBold,
  display: 'inline-block'
}))

export const SemiBoldText = styled(Typography)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightSemiBold,
}))

export const StatsOuterWrap = styled(Box)(({ theme }) => ({
  display: 'flex',

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}));

export const StatsWrap = styled(Box)(({ theme }) => ({
  display: 'inline-flex',
  alignItems: 'center',
  paddingRight: theme.spacing(3),
  paddingLeft: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
    justifyContent: 'center'
  }
}));

export const LoggedInWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  padding: theme.spacing(3),
}));

export const GameOverWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  color: Colors.White,
  alignItems: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  justifyContent: 'flex-start',
  marginBottom: '50vh',
  width: '100vw',

  h1: {
    fontSize: theme.typography.pxToRem(96),

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(44),
    }
  },

  p: {
    padding: 0,
    letterSpacing: theme.spacing(0.1),
    fontSize: theme.typography.pxToRem(20),

    [theme.breakpoints.down('md')]: {
      fontSize: theme.typography.pxToRem(16),
    },

    u: {
      color: Colors.SassyPink
    },
  }
}))