import React, { Ref, useState } from 'react';
import { HoverOverlay } from '../hover-overlay/hover-overlay';
import { TextFieldProps } from './types';
import { StyledTextField, StyledWrap } from './styles';

export const TextField: React.FC<TextFieldProps> = React.forwardRef((
  props: TextFieldProps,
  ref: Ref<HTMLDivElement> | undefined
) => {
  const [isOverlayVisible, setIsOverlayVisible] = useState(false);

  const handleMouseOver = (e: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
    setIsOverlayVisible(e.type === 'mouseover');
  }

  return (
    <StyledWrap>
      <StyledTextField
        {...props}
        ref={ref}
        onMouseOut={handleMouseOver}
        onMouseOver={handleMouseOver}
      />
      <HoverOverlay
        sx={{ borderRadius: props.borderRadius }}
        display={!props.disableHoverOverlay && isOverlayVisible}
      />
    </StyledWrap>
  )
});

