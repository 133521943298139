import { createTheme, Theme } from '@mui/material';
import { Colors } from './colors';

export const theme: Theme = createTheme({
  typography: {
    fontFamily: 'Sora',
    fontWeightLight: 300,
    fontWeightRegular: 400,
    fontWeightMedium: 500,
    fontWeightSemiBold: 600,
    fontWeightBold: 700,
    fontWeightExtraBold: 800,
  },
  palette: {
    error: {
      main: Colors.TomatoRed
    },
    success: {
      main: Colors.HomaGreenLight
    },
  },
});

declare module '@mui/material/styles/createTypography' {
  interface FontStyle {
    fontWeightSemiBold: number;
    fontWeightExtraBold: number;
  }
}


