import { Box } from "@mui/material";
import { LogoFooter, LogoHeader } from '@global/assets';
import { HomaGamesLogoProps } from "./types";

export const HomaGamesLogo: React.FC<HomaGamesLogoProps> = ({ header, ...otherProps }) =>
  <Box {...otherProps}>
    {
      header ?
        <img src={LogoHeader} alt="Homa Games header logo" /> :
        <img src={LogoFooter} alt="Homa Games footer logo" />
    }
  </Box>
