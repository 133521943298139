import { GAME_DAY_END, GAME_MONTH_END, GAME_YEAR_END } from "@global/consts";

export const checkIsGameOver = () => {
  const dateCET = new Intl.DateTimeFormat('en-GB', {
    timeZone: "Europe/Paris"
  }).format(new Date())

  const dateSplit = dateCET.split('/');
  const [currentDay, currentMonth, currentYear] = dateSplit.map(Number);

  return (
    currentYear > GAME_YEAR_END ||
    (currentMonth > GAME_MONTH_END && currentYear === GAME_YEAR_END) ||
    (currentDay >= GAME_DAY_END && currentMonth === GAME_MONTH_END)
  );
}