import { alpha, Backdrop, Box, keyframes, styled } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import { Colors, pinkToBlue } from "@global/colors";
import { hover, moveUp } from "@global/animations";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { transientProps } from "@global/utils";

const blink = keyframes`
  0% { 
    border-color: ${Colors.TomatoRed};
  }
  25%{
    border-color: ${Colors.White};
  }
  50%{
    border-color: ${Colors.TomatoRed};
  }
  75%{
    border-color: ${Colors.White};
  }
  100% {
    border-color: ${Colors.TomatoRed};
  } 
`

export const NotificationContainer = styled(Box, transientProps('error'))<{ error?: boolean }>(({ theme, error }) => ({
  '&:before': {
    content: '" "',
    position: 'absolute',
    inset: 0,
    padding: '1px',
    background: `${error ? Colors.TomatoRed : pinkToBlue(1, 'bottom')}`,
    WebkitMask: 'linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0)',
    WebkitMaskComposite: 'xor',
    MaskComposite: 'exclude',
    pointerEvents: 'none',
  },

  top: '10vh',
  minWidth: '150px',
  maxWidth: '450px',
  position: 'absolute',
  padding: theme.spacing(2, 3),
  background: alpha(Colors.RoyalPurple, 0.9),
  color: `${error ? Colors.TomatoRed : Colors.White}`,
  animation: `
       ${moveUp} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms ease-out both,
       ${blink} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms linear both ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms,
       ${hover} 3s linear infinite ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 1.95}ms;
  `,

  [theme.breakpoints.down('sm')]: {
    maxWidth: '90vw'
  }
}));

export const IconWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  marginBottom: theme.spacing(1),

  'img, svg': {
    marginRight: theme.spacing(0.5)
  }
}));

export const StyledBackdrop = styled(Backdrop)(({ theme }) => ({
  zIndex: theme.zIndex.appBar + 1
}))

export const StyledCloseIcon = styled(CloseIcon, transientProps('error'))<{ error?: boolean }>(({ error }) => ({
  color: error ? alpha(Colors.TomatoRed, 0.6) : alpha(Colors.SassyPink, 0.8),
}));

export const NotificationHeaderWrap = styled(Box, transientProps('error'))<{ error?: boolean }>(({ error }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'space-between',
}))