import { Typography, Link } from "@mui/material";
import { HOMA_DISCORD_CHANNEL_URL } from "./consts";
import { StyledWrap } from "./styles"

export const MailingListIssueInfoNotification: React.FC = () =>
  <StyledWrap>
    <Typography sx={{ mb: 2 }}>
      Something went wrong.
    </Typography>
    <Typography>
      Please try again or contact us on <Link href={HOMA_DISCORD_CHANNEL_URL} >Discord</Link> for support!
    </Typography>
  </StyledWrap>
  ;