import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { ThemeProvider } from '@mui/material';
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
} from "@apollo/client";
import {
  createBrowserRouter,
  Navigate,
  RouterProvider,
} from "react-router-dom";
import { config } from '@config';
import { theme } from '@global/theme';
import { Web3Provider, PageProvider } from '@components'
import './index.css';
import { App } from './app';
import { checkIsGameOver } from '@global/utils';
import { Rankings } from "./views/rankings/rankings";

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

const client = new ApolloClient({
  uri: config.REACT_APP_GQL_SERVER_URI,
  cache: new InMemoryCache()
});

const router = createBrowserRouter([
  {
    index: true,
    path: "/play",
    element: <App />,
  },
  {
    path: "/rankings",
    element: <Rankings />,
  },
  {
    path: "*",
    element: <Navigate to={checkIsGameOver() === true ? "/rankings" : "/play"} replace />
  },
]);

root.render(
  <React.StrictMode>
    <ApolloProvider client={client}>
      <ThemeProvider theme={theme}>
        <PageProvider>
          <Web3Provider>
            <RouterProvider router={router} />
          </Web3Provider>
        </PageProvider>
      </ThemeProvider>
    </ApolloProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
