import { Notification } from "@components";
import { InfoNotificationProps } from "./types";
import { MailingListIssueInfoNotification } from "./notification-types";
import { NotificationType } from "@global/types";
import { GenericInfoNotification } from "./notification-types/generic-info-notification/generic-info-notification";

export const InfoNotification: React.FC<InfoNotificationProps> = ({ onClose, type, data }: InfoNotificationProps) =>
  <Notification info onClose={onClose}>
    {type === NotificationType.MAILING_LIST_ISSUE && <MailingListIssueInfoNotification />}
    {type === NotificationType.GENERIC && <GenericInfoNotification text={data.text} />}
  </Notification>

