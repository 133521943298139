import * as Yup from 'yup';

export const validationSchema = Yup.object().shape({
  message: Yup.string().required('Too much of silence can create distance in every beautiful relation.'),
});

export const SIGN_UP_SUCCESS_FEEDBACK_MILLISECONDS = 2000;
export const CHARACTER_MAX_LIMIT_COUNT = 500;
export const CHARACTER_MIN_LIMIT_COUNT = 50;
export const TIME_UNTIL_NEW_GAME_MINUTES = 1;
export const TIME_UNTIL_NEW_GAME_LS_KEY = 'date-time-until-new-game';
export const USER_STATS_LS_KEY = 'user-stats';