export enum MintStatus {
  NotListed = 0,
  Listed = 1,
  NotListedMintedOnce = 2,
  ListedMintedOnce = 3,
  ListedMintedTwice = 4,
}

export const GET_MINT_PHASE_POLL_MILLISECONDS = 45000;

export const GET_TOTAL_REVEALED_POLL_MILLISECONDS = 30 * 1000;

export const STAKED_VALENTINES_KEY = 'staked'

export const BLOCK_TO_START_FROM = 15988189;
// export const BLOCK_TO_START_FROM = 8105942;

export const CHUNKED_REQUESTS_COUNT = 100;