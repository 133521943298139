import { Fade, IconButton, Typography, } from '@mui/material';
import { ImportantIcon } from '@global/assets';
import InfoIcon from '@mui/icons-material/Info';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';
import { useEffect, useRef, useState } from 'react';
import { NotificationContainer, IconWrap, StyledBackdrop, StyledCloseIcon, NotificationHeaderWrap } from './styles';
import { NotificationProps } from './types';

export const Notification: React.FC<NotificationProps> = ({ info, error, onClose, children }: NotificationProps) => {
  const [isOpen, setIsOpen] = useState(true)

  const notificationContainerRef = useRef(null);

  const handleCloseNotification = () => {
    setIsOpen(false);
    onClose();
  };

  const handleErrorCloseOnOutsideContainerClick = (event?: any) => {
    const errorContainer = notificationContainerRef.current;

    if (!!event && !!errorContainer && !event.target.contains(errorContainer)) {
      return;
    }

    handleCloseNotification();
  }

  useEffect(() => {
    document.addEventListener('keydown', (e) => {
      if (e.key === 'Escape' && isOpen) {
        handleCloseNotification();
      }
    })
  });

  useEffect(() => {
    if (!!error && !isOpen) {
      setIsOpen(!!error)
    }
  }, [error])

  return (
    <StyledBackdrop open={isOpen} onClick={handleErrorCloseOnOutsideContainerClick}>
      <Fade in={isOpen} timeout={DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS} unmountOnExit>
        <NotificationContainer ref={notificationContainerRef} error={error}>
          <NotificationHeaderWrap error={error}>
            {
              error &&
              <IconWrap>
                <img src={ImportantIcon} alt="Warning" />
                <Typography fontWeight="bold">
                  Error
                </Typography>
              </IconWrap>
            }
            {
              info &&
              <IconWrap>
                <InfoIcon />
                <Typography fontWeight="bold">
                  Info
                </Typography>
              </IconWrap>
            }
            <IconButton
              sx={{ p: 0 }}
              onClick={handleCloseNotification}
            >
              <StyledCloseIcon error={error} />
            </IconButton>
          </NotificationHeaderWrap>
          {children}
        </NotificationContainer>
      </Fade>
    </StyledBackdrop >
  )
}