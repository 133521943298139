import { Box, keyframes, styled } from "@mui/material";


const motionPath = keyframes`
0% {
  transform: rotate(0deg) translateX(20vw);
}
100% {
  transform: rotate(360deg) translateX(20vw);
}
`

const motionPath2 = keyframes`
0% {
  transform: rotate(0deg) translateX(-40vw) rotate(0deg)
}
50% {
  transform: rotate(180deg) translateX(-10vw) rotate(-180deg);
}
100% {
  transform: rotate(360deg) translateX(-40vw) rotate(-360deg);
}
`

const motionPath3 = keyframes`
0% {
  transform: rotate(0deg) translateX(15vw) rotate(0deg);
}
100% {
  transform: rotate(360deg) translateX(15vw) rotate(-360deg);
}
`

const RadialGlow = styled(Box)({
  background: 'radial-gradient(50% 50% at 50% 50%, rgba(255, 145, 250, 0.15) 0%, rgba(196, 196, 196, 0) 100%)',
  position: 'absolute',
  filter: 'blur(4px)',
  borderRadius: '50%',
  zIndex: -100,
})

const glow1Size = 300;

export const RadialGlow1 = styled(RadialGlow)(({ theme }) => ({
  width: `${glow1Size}px`,
  height: `${glow1Size}px`,
  animation: `${motionPath} 6s infinite linear reverse`,
  left: '10%',
  top: '10%',
  opacity: 0.4,

  [theme.breakpoints.down('sm')]: {
    width: `${glow1Size / 3}px`,
    height: `${glow1Size / 3}px`,
  }
}))


const glow2Size = 900;

export const RadialGlow2 = styled(RadialGlow)(({ theme }) => ({
  width: `${glow2Size}px`,
  height: `${glow2Size / 2}px`,
  animation: `${motionPath2} 7s infinite linear reverse`,
  top: '30%',
  right: '0%',
  opacity: 0.5,

  [theme.breakpoints.down('sm')]: {
    width: `${glow2Size / 3}px`,
    height: `${glow2Size / 5}px`,
  }
}))

const glow3Size = 600;

export const RadialGlow3 = styled(RadialGlow)(({ theme }) => ({
  width: `${glow3Size}px`,
  height: `${glow3Size / 1.5}px`,
  animation: `${motionPath3} 6s infinite linear`,
  top: '10%',
  right: '20%',
  opacity: 0.6,

  [theme.breakpoints.down('sm')]: {
    width: `${glow3Size / 3}px`,
    height: `${glow3Size / 4}px`,
  }
}))