import React from "react";
import { ButtonProps } from "./types";
import { StyledButton } from './styles';

export const Button: React.ForwardRefExoticComponent<ButtonProps> = React.forwardRef(({ children, ...otherProps }, ref) => (
  <StyledButton
    ref={ref}
    {...otherProps}
  >
    {children}
  </StyledButton>
));