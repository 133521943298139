import { ethers } from 'ethers';
import { NewValentineSmartContracts, ValentineSmartContracts, Web3Options } from './types';
import { handleWeb3Error } from './handle-web3-error';
import { Maybe, Web3ErrorType } from '@global/types';
import { USER_WALLET_IS_DISCONNECTED } from '@global/consts';
import { selectionContract } from '@component/web3-provider/contracts';

export class Web3 {
  private static _options: Maybe<Web3Options>;
  private static _account: Maybe<string> = null;
  private static _contracts: ValentineSmartContracts | {}
  private static _provider: Maybe<ethers.providers.Web3Provider> = null;

  static async init(contracts: NewValentineSmartContracts, options?: Web3Options) {
    try {
      this._provider = new ethers.providers.Web3Provider(window.ethereum);

      this._contracts = Object.entries(contracts).reduce(
        (acc, [scType, smartContract]) => ({ ...acc, [scType]: smartContract(this.provider) }),
        { 'selection': selectionContract }
      ) as ValentineSmartContracts;
      this._options = options ?? null;

      await this.setUpAccount();

      this._options?.onReady?.();
    } catch (error) {
      handleWeb3Error(error as Web3ErrorType, options?.onError)
    }
  }

  static async connectToWallet() {
    Web3.ensureProviderExists('connectToWallet');

    try {
      const accounts = await Web3.provider.send('eth_requestAccounts', []);

      if (accounts.length > 0) {
        Web3.account = accounts[0];
        this.onAccountsChanged();
      }
    } catch (error) {
      handleWeb3Error(
        error as Web3ErrorType,
        Web3.options?.onError,
        { rejectedRequestType: 'connection' }
      );
    }
  }

  static async changeWallet() {
    Web3.ensureProviderExists('changeWallet');

    try {
      const accounts = await Web3.provider.send(
        "wallet_requestPermissions", [{ eth_accounts: {} }]
      ).then(() => Web3.provider!.send('eth_requestAccounts', []));

      if (accounts.length > 0) {
        Web3.account = accounts[0];
        this.onAccountsChanged();
      }
    } catch (error) {
      handleWeb3Error(
        error as Web3ErrorType,
        Web3.options?.onError,
        { rejectedRequestType: 'change wallet' }
      );
    }
  }

  static get account(): string {
    return this._account!;
  }

  static set account(account: string) {
    this._account = account;
  }

  static set contracts(contracts: ValentineSmartContracts) {
    this._contracts = contracts;
  }

  static set provider(provider: ethers.providers.Web3Provider) {
    this._provider = provider;
  }

  static get options() {
    return this._options;
  }

  static get provider(): ethers.providers.Web3Provider {
    return this._provider!;
  }

  static get contracts(): ValentineSmartContracts {
    return this._contracts as ValentineSmartContracts;
  }

  private static async setUpAccount() {
    window.ethereum.on("accountsChanged", (accounts: string[]) => {
      this._account = accounts.length > 0 ? accounts[0] : null;
      this.onAccountsChanged();
    });

    const hasUserDisconnectedWallet = JSON.parse(localStorage.getItem(USER_WALLET_IS_DISCONNECTED) as string);

    if (!hasUserDisconnectedWallet) {
      const accounts = await this._provider!.listAccounts();
      this._account = accounts[0];
      this.onAccountsChanged();
    }
  }

  private static onAccountsChanged() {
    this._options?.onAccountChange?.(this._account);
    localStorage.setItem(USER_WALLET_IS_DISCONNECTED, JSON.stringify(false));
  }

  private static ensureProviderExists(fnName: String) {
    if (!this._provider) {
      throw new Error(`${fnName} error: provider is missing.`);
    }
  }
}