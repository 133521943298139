import { useContext, useState } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useMutation } from "@apollo/client";
import { yupResolver } from '@hookform/resolvers/yup';
import { PageContext } from '@component/page-provider/context';
import { Button } from "@global/components";
import { Letter } from "@global/assets";
import { NotificationType } from '@global/types';
import { valDay23EntrySubmitMutation, valDay23EntrySubmitMutationData, valDay23EntrySubmitInput } from '@gql/genesis-drop'
import { ValentineLetterFormValues } from './types';
import {
  validationSchema, CHARACTER_MAX_LIMIT_COUNT, CHARACTER_MIN_LIMIT_COUNT,
  TIME_UNTIL_NEW_GAME_MINUTES, TIME_UNTIL_NEW_GAME_LS_KEY, USER_STATS_LS_KEY
} from './consts';
import { LetterImage, LetterInputWrap, StyledTextField, ActionsWrap, TextLimit } from './styles';
import { Web3Context } from '@component/web3-provider/context';

export const ValentineLetter = ({ onUpdate }: { onUpdate: (userStats: any) => void }) => {
  const { setNotification } = useContext(PageContext);
  const { account, doesWalletHoldNFTs } = useContext(Web3Context);
  const [text, setText] = useState('');

  const [isMessageSent, setIsMessageSent] = useState(false);

  const [sendMessage, { loading }] = useMutation<valDay23EntrySubmitMutationData, valDay23EntrySubmitInput>(valDay23EntrySubmitMutation);

  const onSubmit = async ({ message }: ValentineLetterFormValues) => {
    try {
      // const signer = await Web3.provider.getSigner(account!);
      // const signature = await signer.signMessage('A valentine from a gang member.')

      const result = await sendMessage({
        variables: {
          input: {
            text: message,
            address: String(account),
          },
        },
      });

      setValue('message', '');
      setIsMessageSent(true);

      const userStats = result.data?.valDay23EntrySubmit;

      const userStatsNormalized = {
        ...userStats,
        coreMultiplier: userStats !== undefined && userStats.coreMultiplier >= 1 ? String(userStats.coreMultiplier).slice(0, 3) : '-',
        percentile: String(userStats?.percentile).slice(0, 4),
        correctWords: JSON.parse(userStats?.correctWords as string).length
      };

      onUpdate(userStatsNormalized)

      localStorage.setItem(
        USER_STATS_LS_KEY,
        JSON.stringify(userStatsNormalized)
      );

      localStorage.setItem(
        TIME_UNTIL_NEW_GAME_LS_KEY,
        String(Date.now() + TIME_UNTIL_NEW_GAME_MINUTES * 60 * 1000)
      );
    } catch (error) {
      setNotification({
        type: NotificationType.GENERIC,
        data: {
          text: 'Our servers are currently at maximum capacity. Hang tight!'
        }
      });
    }
  }

  const { control, handleSubmit, setValue, formState: { errors } } = useForm<ValentineLetterFormValues>({
    resolver: yupResolver(validationSchema)
  });

  const handleTextUpdate = (e: any) => {
    const newText = e.target.value

    if (newText.length > CHARACTER_MAX_LIMIT_COUNT) return;

    setText(newText);
    setValue('message', newText);
  }

  const isLetterInvalid = text.replace(/[^a-zA-Z]/g, '').length < CHARACTER_MIN_LIMIT_COUNT || text.length === CHARACTER_MAX_LIMIT_COUNT;

  return (
    <form onSubmit={handleSubmit(onSubmit)} style={{ width: '100%', height: '75vh' }}>
      <LetterInputWrap>
        <LetterImage src={Letter} />
        <Controller
          name="message"
          control={control}
          render={({ field }) =>
            <StyledTextField
              {...field}
              disabled={!doesWalletHoldNFTs}
              autoFocus
              color={isMessageSent ? 'success' : 'error'}
              error={!!errors.message}
              rows={10}
              multiline
              placeholder='Craft the perfect love letter to Valentine!'
              value={text}
              onChange={handleTextUpdate}
            />
          }
        />
        <TextLimit error={isLetterInvalid}>
          {text.length}/500
        </TextLimit>
      </LetterInputWrap>
      <ActionsWrap sx={{ pt: 4 }}>
        <Button
          disabled={!doesWalletHoldNFTs}
          secondary
          onClick={() => setText('')}
          sx={{ mr: 2 }}
        >
          Clear
        </Button>
        <Button
          boldText
          size="large"
          type="submit"
          sx={{ mb: 4 }}
          loading={loading}
          disabled={!doesWalletHoldNFTs || isLetterInvalid}
        >
          Send
        </Button>
      </ActionsWrap>
    </form >
  )
}
