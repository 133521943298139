import { useContext } from "react";
import { SocialId } from '../../../global/components/socials/types';
import { Link } from "react-router-dom";
import { HeaderCTA } from "./components";
import { Box } from "@mui/material";
import { useLocation } from 'react-router-dom';
import { SITE_PLAY_ROUTE, SITE_RANKINGS_ROUTE } from "@global/consts";
import { PageContext } from "@component/page-provider/context";
import {
  FlexWrap,
  StyledHeaderWrap,
  StyledHomaGamesLogo,
  StyledSocials
} from "./styles";

export const Header = () => {
  const location = useLocation();
  const { isGameOver } = useContext(PageContext)

  return (
    <StyledHeaderWrap>
      <FlexWrap>
        <Link to={"/"}>
          <StyledHomaGamesLogo header sx={{ mr: { xl: 6, lg: 6, m: 6, s: 0, xs: 0 } }} />
        </Link>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <HeaderCTA
            name='Play'
            disabled={isGameOver}
            to={isGameOver ? '' : SITE_PLAY_ROUTE}
            active={location.pathname === SITE_PLAY_ROUTE}
          />
          <Box component="span" sx={{ mr: 2 }} />
          <HeaderCTA
            name="Rankings"
            to={SITE_RANKINGS_ROUTE}
            active={location.pathname === SITE_RANKINGS_ROUTE}
          />
        </Box>
      </FlexWrap>
      <FlexWrap>
        <StyledSocials
          sx={{ mr: 2.5 }}
          showonly={[SocialId.Website, SocialId.OpenSea, SocialId.Discord, SocialId.Twitter]}
        />
      </FlexWrap>
    </StyledHeaderWrap >
  )
}
