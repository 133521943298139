import { hover } from "@global/animations";
import { Colors } from "@global/colors";
import { Button } from "@global/components";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";
import { Dialog, DialogActions, styled, Typography } from "@mui/material";

export const StyledDialog = styled(Dialog)(({ theme }) => ({
  borderRadius: '12px',
  textTransform: 'uppercase',

  "& .MuiPaper-root": {
    animation: `${hover} 8s linear infinite ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}ms;`,
    background: '#141416',
    padding: theme.spacing(4, 6),
    minWidth: '50vw',

    [theme.breakpoints.down('sm')]: {
      minWidth: '80vw',
      padding: theme.spacing(2, 1),
    }
  }
}));

export const StyledMainText = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: Colors.White,
  fontSize: theme.typography.pxToRem(36),
  fontWeight: theme.typography.fontWeightExtraBold,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(20),
  }
}));

export const StyledSubText = styled(Typography)(({ theme }) => ({
  fontWeight: 600,
  width: '100%',
  textAlign: 'center',
  color: Colors.White,
  marginTop: theme.spacing(2),
  fontSize: theme.typography.pxToRem(20),

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(16),
  }
}));

export const StyledSelectionButton = styled(Button)(({ theme }) => ({
  '&:not(:first-of-type)': {
    marginLeft: theme.spacing(2)
  },

  [theme.breakpoints.down('sm')]: {
    width: '100%',

    '&:not(:first-of-type)': {
      margin: 0,
      marginTop: theme.spacing(2)
    },
  }
}));

export const StyledDialogActions = styled(DialogActions)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',

  [theme.breakpoints.down('sm')]: {
    flexWrap: 'wrap',
  }
}));