import { styled, Box, Typography } from "@mui/material";
import { LONG_ANIMATION_DURATION_SECONDS } from "./consts";
import { Colors } from "@global/colors";
import { hover } from "@global/animations";

export const StyledHeader = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  textShadow: `4px 4px 4px ${Colors.Black}`,

  fontSize: theme.typography.pxToRem(72),
  fontWeight: theme.typography.fontWeightExtraBold,

  // animation: `${hover(-0.05)} ${LONG_ANIMATION_DURATION_SECONDS}s linear infinite`,

  [theme.breakpoints.down('sm')]: {
    fontSize: theme.typography.pxToRem(36),
    width: 'max-content',
  }
}));

export const StyledSubHeader = styled(Typography)(({ theme }) => ({
  color: Colors.Black,
  fontFamily: 'Sofia Sans',
  fontWeight: 1000,
  marginTop: theme.spacing(2)
}));

export const StyledWrap = styled(Box)({
  textTransform: 'uppercase',
  color: Colors.DumbGrey,
  textAlign: 'center',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',

  animation: `
    ${hover} ${LONG_ANIMATION_DURATION_SECONDS * 1.5}s linear infinite
  `,
});


export const SubHeaderTextWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  backgroundColor: Colors.FunkyYellow,
  marginTop: theme.spacing(),
  marginBottom: theme.spacing(2),
  borderRadius: '12px',
  padding: theme.spacing(2, 6),

  'h2': {
    fontSize: theme.spacing(12),
    margin: 0,
  }
}));