import { LinkProps } from "react-router-dom";
import { StyledHeaderCTA } from "./styles";

export const HeaderCTA = (props: LinkProps & { name: string, active: boolean, disabled?: boolean }) =>
  <StyledHeaderCTA
    style={{ letterSpacing: '0.05rem' }}
    {...props}
  >
    {props.name}
  </StyledHeaderCTA>


