import { ethers } from "ethers";
import mintContractJSON from './mint.json';
import stakingContractJSON from './staking.json';
import selectionContractJSON from './selection.json';
import { NewContract } from "@global/types";

// TESTNET - OLD 
// export const mintContractAddress = "0x5ba15fd8703a5e8b8A3f0f269E7FcA77354D1D3F";
// export const stakingContractAddress = "0x274760D9792d737d19A788e294117a7A24A094B5";
// export const selectionContractAddress = "0x3fF8bB6dEE4468Cb09d482E3431dC2B30fc8588d";

// TESTNET - NEW 
// export const mintContractAddress = "0x48A2122772d779b42d425988a02D2e95Cded39fC";
// export const stakingContractAddress = "0x2B4b6DEA0AbE198D209DB6d81028e53Df1ec05B8";
// export const selectionContractAddress = "0x08081F919E4eE49C8BB4f5cAf1eEEd2Ea4bE3d9a";

// MAINNET - NEW 
export const mintContractAddress = "0x6c869a43a9d362ef870d75dae56a01887578421d";
export const stakingContractAddress = "0x7f358da9b7472714c43b57ab7f351f7936c73a90";
export const selectionContractAddress = "0x501b46Eb66f00E1046c78e19894cb610d430c3bF";

export const mintContract: NewContract = (provider: ethers.Signer | ethers.providers.Provider | undefined) =>
  new ethers.Contract(mintContractAddress, mintContractJSON.abi, provider)

export const stakingContract: NewContract = (provider: ethers.Signer | ethers.providers.Provider | undefined) =>
  new ethers.Contract(stakingContractAddress, stakingContractJSON.abi, provider);

export const polygonProvider = new ethers.providers.InfuraProvider('matic');
export const selectionContract = new ethers.Contract(selectionContractAddress, selectionContractJSON.abi, polygonProvider);