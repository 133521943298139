import { Box, styled } from "@mui/material";

export const HideIn = styled(Box)<{ view: string }>(({ theme, view }) => {
  const [breakpointDirection, breakpointValue] = view.split('.');

  return {
    width: '100%',
    // @ts-ignore
    [theme.breakpoints[breakpointDirection](breakpointValue)]: {
      display: 'none'
    }
  }
});