import { Web3 } from "@global/utils";

export const getNFTsMetadata = (tokenIDs: number[], isRevealed: boolean) => {
  const nftsMetadata = tokenIDs.map(async (tokenId) => {

    if (!isRevealed) {
      return {
        id: tokenId,
        // Unrevealed video
        imageSrc: 'https://apricot-petite-magpie-306.mypinata.cloud/ipfs/QmTnaZqWBZVUxaWHyqMiNcWs3h5gDrnSCAooZnyzVBTJha',
      }
    }

    const tokenUri = await Web3.contracts.minting.tokenURI(tokenId);

    const data = await fetch(tokenUri).then(res => {
      return res.status === 200 ? res.json() : null;
    });

    return {
      id: tokenId,
      ...(data !== null && {
        imageSrc: data.image,
        traits: data.attributes
      })
    }
  });

  return Promise.all(nftsMetadata);
};
