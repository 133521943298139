export const EthereumRpcErrorCode = {
  UserRejectedRequest: 4001,
  Unauthorized: 4100,
  UnsupportedMethod: 4200,
  Disconnected: 4900,
  ChainDisconnected: 4901,
  AlreadyProcessing: -32002
}

const addSpace = (optionalValue?: string) => optionalValue?.padEnd(optionalValue.length + 1, ' ') ?? '';

export const EthereumRpcErrorMessage = {
  AlreadyProcessing: (requestType?: string) => `The ${addSpace(requestType)}request is already being processed. Please open Metamask and finalize your request.`
}

export const EthersErrorMessage = {
  InsufficientFunds: (requiredFunds?: string) =>
    `The user does not have sufficient funds to cover the transaction of ${addSpace(requiredFunds)}ETH + gas. Please top up the wallet with the necessary funds.`
}

export const Errors = {
  messsages: {
    ...EthereumRpcErrorMessage,
    ...EthersErrorMessage,
    Unknown: 'An unexpected error occured. Please refresh the page and contact us through Discord, if the issue persists.',
  },
  codes: {
    ...EthereumRpcErrorCode
  }
}