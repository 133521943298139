export const ManifestoCTA = (props: any) =>
  <a
    target="_blank"
    rel="noreferrer"
    href="https://homagang.xyz/manifesto"
    style={{ letterSpacing: '0.05rem' }}
    {...props}
  >
    Manifesto
  </a>
