import { Colors, fadedWhite06, pinkToBlue } from '@global/colors';
import { Button, TextField, ViewHeader } from '@global/components';
import { Box, styled, Typography } from '@mui/material';
import { transientProps } from '@global/utils';
import { clipText } from '@global/animations';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from '@global/consts';

export const StyledHeader = styled(ViewHeader)({
  animation: `${clipText} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}ms cubic-bezier(0.0, 0.0, 0.2, 1) both ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 5}ms`,
});

export const ContentWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'flex-end',

  [theme.breakpoints.up('md')]: {
    paddingLeft: theme.spacing(7),
    paddingRight: theme.spacing(7),
  },
}));

export const ContentWrapCenter = styled(ContentWrap)({
  justifyContent: 'center',
})


export const ValentineGameText = styled(Typography)(({ theme }) => ({
  width: '60%',
  marginLeft: '20%',
  marginRight: '20%',
  textAlign: 'center',
  color: Colors.White,
  textTransform: 'uppercase',
  fontSize: theme.typography.pxToRem(14),
  fontWeight: theme.typography.fontWeightExtraBold,
  textShadow: '2px 2px 4px rgba(0, 0, 0, 0.5)',
  animation: `${clipText} ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 2}ms cubic-bezier(0.0, 0.0, 0.2, 1) both ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 5}ms`,

  [theme.breakpoints.down('md')]: {
    fontSize: theme.typography.pxToRem(14),
  }
}));

export const InfoCTA = styled(Typography)(({ theme }) => ({
  textAlign: 'center',
  color: Colors.SassyPink,
  textDecoration: 'underline',
  marginBottom: theme.spacing(1),
  transition: 'color 0.3s ease-out',

  '&:hover': {
    cursor: 'pointer',
    color: Colors.ShyPink,
  }
}));

export const InfoContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  flexDirection: 'column',

  padding: theme.spacing(2),
  background: pinkToBlue(0.1),
  maxWidth: '90vw',

  'p': {
    lineHeight: theme.spacing(4),
  },

  [theme.breakpoints.up('md')]: {
    maxWidth: '650px',
  }
}))

export const InfoText = styled(Typography)(({ theme }) => ({
  color: fadedWhite06,
  textAlign: 'center',
  lineHeight: theme.spacing(3),

  [theme.breakpoints.down('sm')]: {
    textAlign: 'left',
    fontSize: theme.typography.pxToRem(14),

    '.space': {
      display: 'none'
    }
  }
}))

export const BoldTextButton = styled(Button)(({ theme }) => ({
  '.MuiTypography-root': {
    fontWeight: theme.typography.fontWeightExtraBold
  },
}))

export const LetterImage = styled('img')(({ theme }) => ({
  position: 'fixed',
  width: '50vw',
  top: '10%',
  left: '25%',
  height: '80vh',

  [theme.breakpoints.down('md')]: {
    width: '100vw',
    left: 0,
  }
}))

const letterWidthDesktopVW = 40;
const letterWidthMobileVW = 80;

export const LetterInputWrap = styled(Box)(({ theme }) => ({
  position: 'relative',
  width: `${letterWidthDesktopVW}vw`,
  height: '60vh',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  gap: theme.spacing(2),
  marginBottom: theme.spacing(2),

  fontFamily: 'Habibi',
  'textarea, input': {
    fontFamily: 'Habibi',
  },

  [theme.breakpoints.down('md')]: {
    width: `${letterWidthMobileVW}vw`,
    marginLeft: '10vw',
    marginRight: '10vw',
    left: 0,
  }
}));

const letterInputSpacingPX = 32;

export const StyledTextField = styled(TextField)(({ theme }) => ({
  width: `calc(${letterWidthDesktopVW}vw - ${letterInputSpacingPX * 2}px)`,

  textarea: {
    left: 0,
    width: '90%',
    position: 'absolute',
    top: theme.spacing(8),
  },

  [theme.breakpoints.down('md')]: {
    width: `calc(${letterWidthMobileVW}vw - ${letterInputSpacingPX * 2}px)`,
  }
}))

export const ClearTextCTA = styled(Typography)(({ theme }) => ({
  color: Colors.White,
  display: 'inline-block',
  fontWeight: theme.typography.fontWeightSemiBold
}))

export const ActionsWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'baseline',
  justifyContent: 'center',
  width: '100%',
}));

export const TextLimit = styled(Box, transientProps('error'))<{ error: boolean }>(({ theme, error }) => ({
  zIndex: 1,
  color: error ? Colors.TomatoRed : Colors.Black,
  opacity: 0.7,
  position: 'absolute',
  left: letterInputSpacingPX,
  bottom: theme.spacing(2),
}));

export const StyledViewHeader = styled(ViewHeader)(({ theme }) => ({
  h1: {
    fontSize: theme.typography.pxToRem(32),
    fontWeight: theme.typography.fontWeightExtraBold
  }
}));