import { BoxProps } from "@mui/material";

export enum SocialId {
  Twitter = 'twitter',
  Discord = 'discord',
  OpenSea = 'opensea',
  Website = 'website',
  Instagram = 'instagram',
  TikTok = 'tiktok',
  Medium = 'medium',
}

export type SocialsProps = BoxProps & {
  showonly?: SocialId[]
}