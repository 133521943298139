import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

// TODO: make it fetch only top 10
export const rankingsQuery: DocumentNode = gql`
  query rankings {
    valDay23Leaderboard {
      rank
      highScore
      address
    }
  }
`;

export const userStatsQuery: DocumentNode = gql`
  query userStats($options: ValDay23UserStatsOptions!) {
    valDay23UserStats(options: $options) {
      rank
      highScore
    }
  }
`;