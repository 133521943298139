import { Web3 } from "@global/utils";
import { BLOCK_TO_START_FROM } from "../consts";
import { stakingContractAddress } from "../contracts";
import { RevealedTokenIDs } from "../types";

export const getRevealedTokenIDs = async (walletTokenIds: number[]): Promise<RevealedTokenIDs> => {
  try {
    const transferFromStakingEventsQuery = await Web3.contracts.minting.filters.Transfer(stakingContractAddress, null)
    const transferFromStakingEvents = await Web3.contracts.minting.queryFilter(transferFromStakingEventsQuery, BLOCK_TO_START_FROM)
    const transferFromStakingTokenIds = transferFromStakingEvents.map((event: any) => event.args?.tokenId.toNumber());

    const walletTokenIdsRevealed = walletTokenIds.filter((tokenId) => transferFromStakingTokenIds.includes(tokenId));

    if (walletTokenIdsRevealed.length === 0) {
      return {};
    }

    return walletTokenIdsRevealed.reduce((acc, tokenId) => ({ ...acc, [tokenId]: true }), {});
  } catch (error) {
    console.error('-- getRevealedTokenIDs() --')
    console.error(error)
    console.error('-- getRevealedTokenIDs() --')

    return {};
  }
};