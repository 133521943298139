import { useState, useEffect, useContext, useRef } from 'react';
import { ButtonProps, Fade, Typography } from '@mui/material';
import MetaMaskOnboarding from '@metamask/onboarding';
import { BtnText } from './consts';
import { Web3Context } from '@component/web3-provider/context';
import { StyledButton } from './styles';
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS, fadeTimeoutSettings } from '@global/consts';
import { UpArrowIcon } from '@global/assets';
import { TransitionProps } from '@mui/material/transitions';

export const MetamaskButton = (props: ButtonProps & { timeout?: TransitionProps['timeout'] }) => {
  const { account, mintCheck, connectToWallet } = useContext(Web3Context);

  const [readyToShow, setReadyToShow] = useState(false);

  const [buttonText, setButtonText] = useState(BtnText.InstallMetamask)

  const buttonRef = useRef<HTMLButtonElement>(null);

  useEffect(() => {
    if (!MetaMaskOnboarding.isMetaMaskInstalled()) {
      setButtonText(BtnText.InstallMetamask);
    } else if (!account) {
      setButtonText(BtnText.ConnectWallet);
    }
  }, [account, mintCheck?.isEligible]);

  useEffect(() => {
    setTimeout(() => {
      setReadyToShow(true);
    }, DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS * 3);
  }, []);

  return (
    <Fade
      unmountOnExit
      in={!account && readyToShow}
      timeout={props.timeout ?? fadeTimeoutSettings}
    >
      <StyledButton
        {...props}
        boldText
        size="large"
        ref={buttonRef}
        onClick={connectToWallet}
      >
        <Typography sx={{ mr: 2 }}>
          {buttonText}
        </Typography>
        <img src={UpArrowIcon} alt="Up arrow" />
      </StyledButton>
    </Fade>
  )
};