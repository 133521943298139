import { GradientText } from "@global/components"
import { LinearProgress, styled } from "@mui/material"

export const StyledLinearProgress = styled(LinearProgress)({
  width: "100%",
  height: '24px',
  borderRadius: '8px',
  backgroundColor: 'rgba(245, 245, 245, 0.2)',
  '& .MuiLinearProgress-bar': {
    background: `linear-gradient(#F6A1DF, #9EBBFF)`,
  }
})

export const StyledGradientText = styled(GradientText)(({ theme }) => ({
  fontWeight: theme.typography.fontWeightMedium
}))