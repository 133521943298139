import { Layout } from "@component/layout/layout";
import { PageContext } from "@component/page-provider/context";
import { Web3Context } from "@component/web3-provider/context";
import { NotificationType } from "@global/types";
import { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { NotConnectedView } from "./views/reveal-main/not-connected";
import { ValentineGameView } from "./views/reveal-main/valentine-game-view";

export const App = () => {
  const { account, doesWalletHoldNFTs } = useContext(Web3Context);
  const { setNotification, isGameOver } = useContext(PageContext);

  const navigate = useNavigate();

  useEffect(() => {
    if (doesWalletHoldNFTs === false) {
      setNotification({
        type: NotificationType.GENERIC,
        data: {
          text: (
            <>
              You currently don’t own a Valentine NFT. <br />
              You need to own at least one in order to send letters to Valentine and participate in the game.
            </>
          )
        }
      })
    }
  }, [account, doesWalletHoldNFTs]);

  useEffect(() => {
    if (isGameOver) {
      navigate('/rankings')
    }
  }, [isGameOver]);

  return (
    <Layout dimmed={!!account}>
      {!account ? <NotConnectedView /> : <ValentineGameView />}
    </Layout>
  )
}