import { TOTAL_AMOUNT_NFTS } from "@global/consts";
import { Web3 } from "@global/utils";

export const getTotalRevealed = async () => {
  try {
    const totalUnrevealed = await Web3.contracts.selection.getAvailableMetadata();
    const totalRevealed = TOTAL_AMOUNT_NFTS - totalUnrevealed.length;

    localStorage.setItem('last-total-revealed', totalRevealed.toString(10));
    return totalRevealed;
  } catch (error) {
    return parseInt(localStorage.getItem('last-total-revealed') ?? '0', 10);
  }
}