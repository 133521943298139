import { Link } from "react-router-dom";
import { styled } from "@mui/material";
import { Colors } from "@global/colors";
import { transientProps } from "@global/utils";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";

export const StyledHeaderCTA = styled(Link, transientProps('active', 'name', 'disabled'))<
  { active: boolean, disabled?: boolean }
>(({ theme, active, disabled }) => ({
  fontSize: theme.typography.pxToRem(14),
  paddingTop: theme.spacing(1),
  marginLeft: theme.spacing(-0.25),
  cursor: 'pointer',
  transition: `color ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms ease-in-out`,
  color: active ? Colors.SassyPink : Colors.White,

  ...(disabled && {
    opacity: '0.5 !important',
    cursor: 'not-allowed',
  })
}))