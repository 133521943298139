import { alpha } from '@mui/material';

export const Colors = {
  'HomaGreen': '#73D4AE',
  'HomaGreenLight': '#9BFFE1',
  'FunkyYellow': '#FFF4D9',
  'Black': '#000000',
  'White': '#ffffff',
  'SassyPink': '#FFD9FB',
  'DumbGrey': '#E5E5E5',
  'ShyPink': '#FFCCFD',
  'MysticBlue': '#131F35',
  'TomatoRed': '#FF8787',
  'RoyalPurple': '#15172C',
  'RoyalDarkBlue': '#0E142A',
  'RoyalDarkPurple': '#261C2E',
  'CoolDarkBlue': '#1C1C1C',
  'SkyBlue': '#C4DFFE',
  'SassyPurple': '#681864',
}

export type GradientDirection = 'top' | 'right' | 'bottom' | 'left';

export const pinkToBlue = (opacity?: number, direction?: GradientDirection) =>
  `linear-gradient(to ${direction || 'right'}, ${alpha(Colors.SassyPink, opacity || 1)} 0%, ${alpha(Colors.SkyBlue, opacity || 1)} 80%)`

export const fadedWhite03 = alpha(Colors.White, 0.3);
export const fadedWhite06 = alpha(Colors.White, 0.6);
export const fadedWhite08 = alpha(Colors.White, 0.8);
