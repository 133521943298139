import { Box, IconButton, styled } from "@mui/material";
import { Colors, fadedWhite08 } from "@global/colors";
import { DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS } from "@global/consts";

export const SocialsWrap = styled(Box)(({ theme }) => ({
  display: 'flex',
  gridGap: theme.spacing(2.5)
}))

const btnSizePixels = 44;

export const StyledSocialButton = styled(IconButton)({
  position: 'relative',
  border: `1px solid ${Colors.White}`,
  borderRadius: 0,
  width: `${btnSizePixels}px`,
  height: `${btnSizePixels}px`,

  transition: `border-color ${DEFAULT_ANIMATION_TIMEOUT_MILLISECONDS}ms ease-in`,

  '&:hover': {
    borderColor: fadedWhite08,
  },

  svg: {
    fill: Colors.White
  }
})

export const Empty = styled(Box)(({ theme }) => ({
  visibility: 'hidden',

  [theme.breakpoints.up('sm')]: {
    display: 'none'
  }
}));