import React from "react";
import { Fade } from "@mui/material";
import { Header } from "@components"
import { PageWrapper, Main } from "./styles"
import { FADE_IN_TIMEOUT_MILLISECONDS } from "@global/consts";

export const Layout: React.FC<{ children: React.ReactNode, dimmed?: boolean }> = ({ children, dimmed }) =>
  <>
    <Fade in timeout={FADE_IN_TIMEOUT_MILLISECONDS}>
      <PageWrapper dimmed={dimmed}>
        <Header />
        <Main>
          {children}
        </Main>
      </PageWrapper>
    </Fade>
  </>