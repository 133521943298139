import { StyledHeader, StyledSubHeader, StyledWrap, SubHeaderTextWrap } from "./styles";
import { ViewHeaderProps } from "./types";

export const ViewHeader: React.FC<ViewHeaderProps> = ({ headerText, subHeaderText, subHeaderProps, ...wrapperProps }) =>
  <StyledWrap {...wrapperProps}>
    <StyledHeader variant="h1">
      {headerText}
    </StyledHeader>
    {
      subHeaderText &&

      <SubHeaderTextWrap {...subHeaderProps}>
        <StyledSubHeader variant="h2">
          {subHeaderText}
        </StyledSubHeader>
      </SubHeaderTextWrap>
    }
  </StyledWrap>