import { gql } from "@apollo/client";
import { DocumentNode } from "graphql";

export const valDay23EntrySubmitMutation: DocumentNode = gql`
  mutation valDay23EntrySubmitMutation($input: ValDay23EntrySubmitInput!) {
    valDay23EntrySubmit(input:$input) {
      score
      correctWords
      coreMultiplier
      percentile
      numTries
      rank
    }
  }
`;